<template>
  <div class="homepage grassland-mobile">
    <public-header></public-header>
    <div class="banner">
       <img src="@/assets/images/asset-trad-mobile/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("assettradTitle")}}</p>
    </div>
    <!-- 内容区域   开始 -->
    <p class="asset-trad-title">
      {{$t("assettradSubTitle1")}}
      {{$t("assettradSubTitle2")}}
      <!-- {{$t("assettradSubTitle2")}} -->
    </p>
    <!-- 2 -->
    <div class="card">
      <img src="@/assets/images/asset-trad-mobile/map.png" alt="">
      <ul>
        <li>
          <img src="@/assets/images/asset-trad-mobile/tree.png" alt="">
          <span>{{$t("assettradMapItem1")}}</span>
        </li>
        <li>
          <img src="@/assets/images/asset-trad-mobile/tree.png" alt="">
          <span>{{$t("assettradMapItem2")}}</span>
        </li>
        <li>
          <img src="@/assets/images/asset-trad-mobile/tree.png" alt="">
          <span>{{$t("assettradMapItem3")}}</span>
        </li>
      </ul>
    </div>
    <!-- 3 -->
    <div class="carousel-wrap">
      <carousel :height="locale === 'zh-CN'? '8.6rem' : '10rem'" swiperName="swiper8"  paginationClassName="pagination8">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/asset-trad-mobile/pic1.png" :alt='$t("assettradProjectItem1Title")'>
            <div class="swiper-slide-content">
              <p class="slide-title">{{$t("assettradProjectItem1Title")}}</p>
              <p>{{$t("assettradProjectItem1SubTitle")}}</p>
              <router-link class="btn" :to="{path:'/carbon'}">
                <p class="btn-title">{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
              </router-link>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/asset-trad-mobile/pic2.png" :alt='$t("assettradProjectItem1Title")'>
            <div class="swiper-slide-content">
              <p class="slide-title">{{$t("assettradProjectItem2Title")}}</p>
              <p>{{$t("assettradProjectItem2SubTitle")}}</p>
              <router-link class="btn" :to="{path:'/green-certificate'}">
                <p class="btn-title">{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
              </router-link>
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <!-- 内容区域   结束 -->
    <info-submit />
    <public-footer></public-footer>
  </div>
</template>
<script>
import carousel from '@/components/carousel.vue';
import infoSubmit from '@/components/info/index-mobile.vue';
export default {
  components: {
    carousel,
    infoSubmit
  },
  data() {
    return {
      locale: "",
    }
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img ,.about-road img,.team-list li img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }

.grassland-mobile .asset-trad-title {
  padding: 1rem 0.4rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.5rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0;
}
.card {
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  margin: 0 0.4rem;
  padding:0.4rem;
}
.card img {
  width: 100%;
  height: 3.4rem;
  margin: 0.76rem 0;
}
.card li {
  margin-bottom: 0.2rem;
  padding-left: 0.6rem;
  position: relative;
  margin-bottom: 0.2rem;
}
.card li img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.4rem;
  height: 0.4rem;
  margin:0;
}
.card li span {
  display: inline-block;
  height: 0.4rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.4rem;
  text-align: left;
  font-style: normal;
}
.carousel-wrap {
  margin: 1rem 0 0.6rem;
}
.carousel-wrap .swiper-slide-content {
  padding: 0 0.4rem;
}
.carousel-wrap .swiper-slide img {
  width: 100%;
  height: 3.76rem;
}
.carousel-wrap .swiper-slide .slide-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0;
}
.carousel-wrap .swiper-slide p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
}
.carousel-wrap .swiper-slide .btn-title{
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
  color: #9DC86A;
  line-height: 0.28rem;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0;
  position: absolute;
  bottom: 0;
  left: 0.4rem;
}
</style>
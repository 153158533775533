<template>
  <div class="homepage carbon-mobile">
    <public-header></public-header>
    <div class="banner">
       <img src="@/assets/images/carbon/banner-carbon.png" :alt='$t("carbonTitle")'>
       <p class="title">{{$t("carbonTitle")}}</p>
    </div>
    <!-- 内容区域   开始 -->
    <h6 class="carbon-title">{{$t("carbonSubTitle1")}}</h6>
    <div class="carousel-warp">
      <carousel height="auto" swiperName="swiper9"  paginationClassName="pagination9">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <div>
                <img src="@/assets/images/carbon/tree-icon.png" alt="">
                <span>{{$t("carbonQualityTitle1")}}</span>
              </div>
              <p class="swiper-slide-content" 
              :class="{
                'swiper-slide-content-open': (openAnimal && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal && locale === 'en-US')
              }">{{$t("carbonQualityContent1")}}</p>
            </div> 
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <div>
                <img src="@/assets/images/carbon/tree-icon.png" alt="">
                <span>{{$t("carbonQualityTitle2")}}</span>
              </div>
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal && locale === 'en-US')
              }">{{$t("carbonQualityContent2")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <div>
                <img src="@/assets/images/carbon/tree-icon.png" alt="">
                <span>{{$t("carbonQualityTitle3")}}</span>
              </div>
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal && locale === 'en-US')
              }">{{$t("carbonQualityContent3")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <div>
                <img src="@/assets/images/carbon/tree-icon.png" alt="">
                <span>{{$t("carbonQualityTitle4")}}</span>
              </div>
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal && locale === 'en-US')
              }">{{$t("carbonQualityContent4")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <div>
                <img src="@/assets/images/carbon/tree-icon.png" alt="">
                <span>{{$t("carbonQualityTitle5")}}</span>
              </div>
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal && locale === 'en-US')
              }">{{$t("carbonQualityContent5")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <div>
                <img src="@/assets/images/carbon/tree-icon.png" alt="">
                <span>{{$t("carbonQualityTitle6")}}</span>
              </div>
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal && locale === 'en-US')
              }">{{$t("carbonQualityContent6")}}{{$t("carbonQualityContent61")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <h6 class="carbon-title">{{$t("carbonSubTitle2")}}</h6>
    <div class="carousel-warp swiper10">
      <carousel height="auto" swiperName="swiper10"  paginationClassName="pagination10">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide-write-bg">
            <div class="swiper-slide-title">
              <img class="swiper-slide-icon" src="@/assets/images/carbon/standard1.png" alt="">
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal1 && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal1 && locale === 'en-US')
              }">{{$t("carbonStandardContent1")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal1" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun1">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun1">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-title">
              <img class="swiper-slide-icon" src="@/assets/images/carbon/standard2.png" alt="">
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal1 && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal1 && locale === 'en-US')
              }">{{$t("carbonStandardContent2")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal1" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun1">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun1">
            </div>
          </div>
          <div class="swiper-slide swiper-slide-write-bg">
            <div class="swiper-slide-title">
              <img class="swiper-slide-icon" src="@/assets/images/carbon/standard3.png" alt="">
              <p class="swiper-slide-content" :class="{
                'swiper-slide-content-open': (openAnimal1 && locale === 'zh-CN'),
                'swiper-slide-content-open-en': (openAnimal1 && locale === 'en-US')
              }">{{$t("carbonStandardContent3")}}</p>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal1" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun1">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun1">
            </div>
          </div>
        
      
        
        </div>
      </carousel>
    </div>
    <h6 class="carbon-title">{{$t("carbonSubTitle3")}}</h6>
    <div class="card">
      <p>
        {{$t("carbonContent1")}}<router-link :to="{path: '/project'}">{{$t("carbonContent2")}}</router-link>{{$t("carbonContent3")}}
      </p>
      <div class="card-content">{{$t("carbonBoxContent")}}</div>
      <div class="card">
        <img src="@/assets/images/carbon/type1.png" alt="">
        <p>{{$t("carbonProjectItemTitle1")}}</p>
        <span>{{$t("carbonProjectItemContent1")}}</span>
      </div>
      <div class="card">
        <img src="@/assets/images/carbon/type2.png" alt="">
        <p>{{$t("carbonProjectItemTitle2")}}</p>
        <span>{{$t("carbonProjectItemContent2")}}</span>
        <router-link :to="{path: '/grassland-project'}">
          <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
        </router-link>
      </div>
      <div class="card">
        <img src="@/assets/images/carbon/type3.png" alt="">
        <p>{{$t("carbonProjectItemTitle3")}}</p>
        <span>{{$t("carbonProjectItemContent3")}}</span>
      </div>
    </div>


    <!-- 内容区域   结束 -->
    <info-submit />
    <public-footer></public-footer>
  </div>
</template>
<script>
import infoSubmit from '@/components/info/index-mobile.vue';
import carousel from '@/components/carousel.vue';
export default {
  components: {
    infoSubmit,
    carousel
  },
  data() {
    return {
      locale: "",
      openAnimal: false,
      openAnimal1: false,
    }
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
  methods: {
    openAnimalFun() {
      this.openAnimal = !this.openAnimal
    },
    openAnimalFun1() {
      this.openAnimal1 = !this.openAnimal1
    },
   
  }
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img ,.about-road img,.team-list li img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .carbon-mobile .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }
.carbon-title {
  padding: 1rem 0.4rem 0.5rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.48rem;
  color: #333333;
  line-height: 0.48rem;
  letter-spacing: 1px;
  text-align: center;
  font-style: normal;
  margin: 0;
}

.carousel-warp .swiper-slide{
  overflow: hidden;
  position: relative;
  margin-bottom: 0.6rem;
  background: #fff;
}
.carousel-warp .swiper-slide:nth-child(2n-1) {
  background: url(../../assets/images/carbon/content-mobile.png);
  background-size: 115% 115%;
  background-position: -0.2rem;
}

.carousel-warp .swiper-slide .slide-cover {
  height: 1.28rem;
  cursor: pointer;
  text-align: center;
  width: 100%;
  line-height: 1.28rem;
  margin-top: -0.4rem;
  /* position: absolute;
  bottom: 0; */
}
.carousel-warp .swiper-slide .slide-wrap-open {
  height: 5rem;
  overflow: auto;
  padding-bottom: 1.28rem;
}

.carousel-warp .swiper-slide .slide-cover img {
  width: 0.6rem;
  height: 0.48rem;
}

.carousel-warp .swiper-slide-title {
  padding: 0.4rem;
}
.carousel-warp .swiper-slide-title div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  margin-bottom: 0.2rem;
}
.carousel-warp .swiper-slide-title div img {
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.2rem;
}
.carousel-warp .swiper-slide-title div span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  line-height: 0.44rem;
  text-align: left;
  font-style: normal;
  color: #333;
}
.carousel-warp .swiper-slide:nth-child(2n-1)  .swiper-slide-title div span {
  color: #fff;
}
.carousel-warp .swiper-slide-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
  height: 1.12rem;
  overflow: hidden;
}
.carousel-warp .swiper-slide:nth-child(2n-1)  .swiper-slide-content {
  color: #fff;
}
.carousel-warp .swiper-slide-content.swiper-slide-content-open {
  height: 4.5rem;
}
.carousel-warp .swiper-slide-content.swiper-slide-content-open-en {
  height: 7.9rem;
}
.carousel-warp.swiper10 .swiper-slide-content.swiper-slide-content-open {
  height: 5.2rem;
}
.carousel-warp.swiper10 .swiper-slide-content.swiper-slide-content-open-en {
  height: 9rem;
}
.carousel-warp .swiper-slide-title .swiper-slide-icon {
  height: 0.8rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.44rem
} 
.carousel-warp .swiper-slide.swiper-slide-write-bg {
  background: #fff !important;
}
.carousel-warp .swiper-slide.swiper-slide-write-bg .swiper-slide-content {
  color: #333 !important;
}
.card {
  margin: 0.1rem 0.4rem 0.6rem;
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;
  padding: 0.4rem;
}
.card p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
}
.card p a {
  color: #046030;
  text-decoration: underline;
}

.card .card-content {
  background: url(../../assets/images/carbon/content-mobile.png);
  background-size: 110% 110%;
  padding: 0.4rem;
  font-weight: 500;
  font-size: 0.28rem;
  line-height: 0.56rem;
  color: #FFFFFF;
  text-align: left;
  font-style: normal;
  margin-top: 0.3rem;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.5);
  border-radius: 0.4rem;
  background-position: -0.2rem;
}
.card .card {
  padding: 0 0 0.4rem 0;
  margin: 1rem 0;
}
.card .card:last-child {
  margin-bottom: 0;
}
.card .card img {
  width: 100%
}
.card .card p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0;
  padding: 0 0.4rem;
}
.card .card span {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
  padding: 0 0.4rem;
}
.card .card a p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
  color: #9DC86A;
  line-height: 0.28rem;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0 0;
  padding: 0 0.4rem;
}
</style>
<template>
  <div class="homepage corporate-mobile">
    <public-header></public-header>
    <div class="banner">
       <img src="@/assets/images/corporate/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("corporateTitle")}}</p>
    </div>
    <!-- 内容区域   开始 -->
     <div class="corporate-title" :class="{'corporate-title-en': locale !== 'zh-CN'}">
      {{$t("corporateSubTitle1")}}{{$t("corporateSubTitle2")}}
     </div>
     <div class="card">
      <img src="@/assets/images/corporate/pic1.png" alt="">
      <p>{{$t("corporateItemTitle1")}}</p>
      <span v-if="locale === 'zh-CN'">{{$t("corporateItemSubTitle1")}}</span>
     </div>
     <div class="card">
      <img src="@/assets/images/corporate/pic2.png" alt="">
      <p>{{$t("corporateItemTitle2")}}</p>
     </div>
     <div class="card">
      <img src="@/assets/images/corporate/pic3.png" alt="">
      <p>{{$t("corporateItemTitle3")}}</p>
      <router-link class="btn" :to="{path:'/green-certificate'}">
        <p >{{$t("corporateItemSpan1")}}<i class="el-icon-arrow-right"></i></p>
      </router-link>
      <router-link class="btn" :to="{path:'/grassland-project'}">
        <p >{{$t("corporateItemSpan2")}}<i class="el-icon-arrow-right"></i></p>
      </router-link>
     </div>
     <div class="card">
      <img src="@/assets/images/corporate/pic4.png" alt="">
      <p>{{$t("corporateItemTitle4")}}</p>
      <span v-if="locale === 'zh-CN'">{{$t("corporateItemSubTitle4")}}</span>
     </div>
     <div class="card case-mobile">
      <img v-if="locale === 'zh-CN'" src="@/assets/images/corporate/case-pic.png" alt="icon">
      <img v-else src="@/assets/images/corporate/case-pic-en.png" alt="icon">
      <span>{{$t("corporateCOntent")}}</span>
     </div>
    <!-- 内容区域   结束 -->
    <info-submit />
    <public-footer></public-footer>
  </div>
</template>
<script>
import infoSubmit from '@/components/info/index-mobile.vue';
export default {
  components: {
    infoSubmit
  },
  data() {
    return {
      locale: "",
      openOne: false,
      openTwo: false,
      openThree: false,
    }
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
  methods: {
    openDetail(ev) {
      switch(ev) {
        case 1:
          this.openOne = !this.openOne
          break;
        case 2:
          this.openTwo = !this.openTwo
          break;
        case 3:
          this.openThree = !this.openThree
          break;
      }
    },
    aboutIcon() {
      // let str;
      // if (this.locale === 'zh-CN') {
      //   str = 'imageZH'
      // } else {
      //   str = 'imageEN'
      // }
      // new Viewer(document.getElementById(str), {
      //   inline: true,
      //   viewed() {
      //     viewer.zoomTo(1);
      //   },
      // });
    }
  }
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img ,.about-road img,.team-list li img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .corporate-mobile .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 90%;
  font-size: .36rem;
  font-weight: 600;
  color: #fff;
  line-height: .5rem;
  letter-spacing: .06rem;
  text-align: center;
  margin-left: 5%;
 }
.corporate-mobile .corporate-title {
  padding: 1rem 0.4rem 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.5rem;
  letter-spacing: 1px;
  text-align: center;
  font-style: normal;
  margin: 0;
}
.corporate-mobile .corporate-title-en {
  text-align: left;
}


.card {
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  margin: 1rem 0.4rem;
}
.card img {
  width: 100%;
  height: 3.76rem;
}
.card p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  padding:0.4rem 0.4rem;
}
.card span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
  padding: 0 0.4rem 0.4rem;
  display: inline-block;
}
.card .btn {
  display: block;
}
.card .btn p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
  color: #9DC86A;
  line-height: 0.4rem;
  text-align: left;
  font-style: normal;
  padding: 0 0.4rem 0.4rem;
}
.card .btn p i {
  margin-left: 0.2rem;
}
.case-mobile {
  background: url(../../assets/images/corporate/case-mobile.png);
  background-size: 105% 102%;
  padding: 0.4rem;
  background-position: -0.2rem;
}
.case-mobile span {
  margin-top: 0.3rem;
  color: #fff;
  padding: 0 0;
}
</style>
<template>
  <div class="homepage guazhou-mobile">
    <public-header></public-header>
    <div class="banner">
       <img src="@/assets/images/guazhou-mobile/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("guazhouTitle")}}</p>
    </div>
    <!-- 内容区域   开始 -->
    <div class="card">
      <p>{{$t("guazhouExplainContent")}}</p>
      <div class="represen">
        <div>
          <img src="@/assets/images/guazhou-mobile/30.png" :alt="$t('guazhouExplainData1Title')">
          <p>{{$t("guazhouExplainData1Title")}}</p>
          <span>{{$t("guazhouExplainData1Content")}}</span>
        </div>
        <div>
          <img src="@/assets/images/guazhou-mobile/14.png" :alt="$t('guazhouExplainData2Title')">
          <p>{{$t("guazhouExplainData2Title")}}</p>
          <span>{{$t("guazhouExplainData2Content")}}</span>
        </div>
      </div>
      <div class="represen">
        <div>
          <img src="@/assets/images/guazhou-mobile/27.png" :alt="$t('guazhouExplainData3Title')">
          <p>{{$t("guazhouExplainData3Title")}}</p>
          <span>{{$t("guazhouExplainData3Content")}}</span>
        </div>
        <div>
          <img src="@/assets/images/guazhou-mobile/450.png" :alt='$t("guazhouExplainData4Title")'>
          <p>{{$t("guazhouExplainData4Title")}}</p>
          <span>{{$t("guazhouExplainData4Content")}}</span>
        </div>
      </div>
      <span class="represen-info">{{$t("guazhouExplainDetail1")}}</span>
      <span class="represen-info">{{$t("guazhouExplainDetail2")}}</span>
      <span class="represen-info">{{$t("guazhouExplainDetail3")}}</span>
      <span class="represen-info">{{$t("guazhouExplainDetail4")}}</span>
      <span class="represen-info">{{$t("guazhouExplainDetail5")}}</span>

      <span class="title">{{$t("guazhouExplainDetail5")}}</span>

      <div class="auth-img">
        <img src="@/assets/images/guazhou-mobile/vcs.png">
        <img src="@/assets/images/guazhou-mobile/ccbs.png">
      </div>
      <div v-if="locale==='zh-CN'" class="auth-img-one">
      
        <img src="@/assets/images/guazhou-mobile/1.png">
        <img src="@/assets/images/guazhou-mobile/3.png">
        <img src="@/assets/images/guazhou-mobile/5.png">
      </div>
      <div v-else class="auth-img-one">
        <img src="@/assets/images/guazhou-mobile/en1.png">
        <img src="@/assets/images/guazhou-mobile/en3.png">
        <img src="@/assets/images/guazhou-mobile/en5.png">
      </div>
      <div v-if="locale==='zh-CN'" class="auth-img-two">
        <img src="@/assets/images/guazhou-mobile/13.png">
        <img src="@/assets/images/guazhou-mobile/15.png">
      </div>
      <div v-else class="auth-img-two">
        <img src="@/assets/images/guazhou-mobile/en13.png">
        <img src="@/assets/images/guazhou-mobile/en15.png">
      </div>



    </div>
    <!-- 2 -->
    <div class="card">
      <p class="measure-title">{{$t("measureTitle")}}</p>
      <ul>
        <li>
          <img src="@/assets/images/guazhou-mobile/grass.png" alt="">
          <p>{{$t("measureProject1Title")}}</p>
          <p>{{$t("measureProject1Explain")}}</p>
        </li>
        <li>
          <img src="@/assets/images/guazhou-mobile/grass.png" alt="">
          <p>{{$t("measureProject2Title")}}</p>
          <p>{{$t("measureProject2Explain")}}</p>
        </li>
        <li>
          <img src="@/assets/images/guazhou-mobile/grass.png" alt="">
          <p>{{$t("measureProject3Title")}}</p>
          <p>{{$t("measureProject3Explain")}}</p>
        </li>
        <li>
          <img src="@/assets/images/guazhou-mobile/grass.png" alt="">
          <p>{{$t("measureProject4Title")}}</p>
          <p>{{$t("measureProject4Explain")}}</p>
        </li>
      </ul>

    </div>
    <!-- 3 -->
    <div class="card">
      <p class="measure-title">{{$t("grassSeedsTitle")}}</p>
      <p>{{$t("grassSeedsExplain1")}}{{$t("grassSeedsExplain11")}}{{$t("grassSeedsExplain12")}}</p>
      <p>{{$t("grassSeedsExplain2")}}{{$t("grassSeedsExplain21")}}{{$t("grassSeedsExplain22")}}</p>
    </div>
    <!-- 4 -->
    <div class="carousel-wrap">
      <carousel :height="locale === 'zh-CN' ? 'auto' : 'auto'" swiperName="swiper6"  paginationClassName="pagination6">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/guazhou-mobile/milky.png" :alt='$t("grassSeedsProject1Title")'>
            <p>{{$t("grassSeedsProject1Title")}} <br v-if="locale !== 'zh-CN'" /> <span :class="{'clear-margin-left': locale !== 'zh-CN'}">{{$t("grassSeedsProject1Title1")}}</span></p>
            <div class="slide-wrap"  :class="openBotany ? 'slide-wrap-open' : ''">
              <span>{{$t("grassSeedsProject1Explain")}}</span>
            </div>
            <div class="slide-cover">
              <img v-if="openBotany" src="@/assets/images/guazhou-mobile/open.png" @click="open">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="open">
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/guazhou-mobile/crest.png" :alt='$t("grassSeedsProject1Title")'>
            <p>{{$t("grassSeedsProject2Title")}} <br v-if="locale !== 'zh-CN'" /> <span :class="{'clear-margin-left': locale !== 'zh-CN'}">{{$t("grassSeedsProject2Title1")}}</span></p>
            <div class="slide-wrap"  :class="openBotany ? 'slide-wrap-open' : ''">
              <span>{{$t("grassSeedsProject2Explain")}}</span>
            </div>
            <div class="slide-cover">
              <img v-if="openBotany" src="@/assets/images/guazhou-mobile/open.png" @click="open">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="open">
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/guazhou-mobile/sheep.png" :alt='$t("grassSeedsProject1Title")'>
            <p>{{$t("grassSeedsProject3Title")}}<br v-if="locale !== 'zh-CN'" /> <span :class="{'clear-margin-left': locale !== 'zh-CN'}">{{$t("grassSeedsProject3Title1")}}</span></p>
            <div class="slide-wrap"  :class="openBotany ? 'slide-wrap-open' : ''">
              <span>{{$t("grassSeedsProject3Explain")}}</span>
              <span class="lenged">{{$t("grassSeedsProject3Explain")}}</span>
            </div>
            <div class="slide-cover">
              <img v-if="openBotany" src="@/assets/images/guazhou-mobile/open.png" @click="open">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="open">
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/guazhou-mobile/alkali.png" :alt='$t("grassSeedsProject1Title")'>
            <p>{{$t("grassSeedsProject4Title")}}<br v-if="locale !== 'zh-CN'" /> <span :class="{'clear-margin-left': locale !== 'zh-CN'}">{{$t("grassSeedsProject4Title1")}}</span></p>
            <div class="slide-wrap" :class="openBotany ? 'slide-wrap-open' : ''">
              <span>{{$t("grassSeedsProject4Explain")}}</span>
            </div>
            <div class="slide-cover">
              <img v-if="openBotany" src="@/assets/images/guazhou-mobile/open.png" @click="open">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="open">
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <!-- 5 -->
    <div class="card">
      <p class="measure-title">{{$t("influenceTitle")}}</p>
      <h6>{{$t("influenceSubTitle1")}}</h6>
      <div class="video">
        <video 
          width="100%"
          height="198px"
          id="my-video"
          class="video-js"
          preload="auto"
       
          controls
          :controlBar="{
            progressControl: false,
          }"
          bigPlayButton
          data-setup="{}"
        >
          <source src="../../assets/video/influence.mp4" type="video/mp4" />
        </video>
        <!-- <video width="980px" height="552px" src="../../assets/video/influence.mp4"></video> -->
      </div>
      <span class="influence-title">
        {{$t("influenceExplain")}}
      </span>
    </div>
    <div class="carousel-wrap">
      <h6>{{$t("influenceSubTitle2")}}</h6>
      <carousel :height="locale === 'zh-CN' ? 'auto' : 'auto'" swiperName="swiper7"  paginationClassName="pagination7">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/guazhou-mobile/gobi.png" :alt='$t("influenceProject1Title")'>
            <p>{{$t("influenceProject1Title")}} <span >{{$t("influenceProject1Title1")}}</span></p>
            <div class="slide-wrap"  :class="openAnimal ? 'slide-wrap-open' : ''">
              <p>{{$t("influenceProject1SubTitle1")}}</p>
              <p>{{$t("influenceProject1SubTitle2")}}</p>
              <span>{{$t("influenceProject1Explain")}}</span>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/guazhou-mobile/gazelle.png" :alt='$t("influenceProject2Title")'>
            <p>{{$t("influenceProject2Title")}}<br v-if="locale !== 'zh-CN'" /> <span :class="{'clear-margin-left': locale !== 'zh-CN'}">{{$t("influenceProject2Title1")}}</span></p>
            <div class="slide-wrap"  :class="openAnimal ? 'slide-wrap-open' : ''">
              <p>{{$t("influenceProject2SubTitle1")}}</p>
              <p>{{$t("influenceProject2SubTitle2")}}</p>
              <span>{{$t("influenceProject2Explain")}}</span>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/guazhou-mobile/snow.png" :alt='$t("influenceProject3Title")'>
            <p>{{$t("influenceProject3Title")}}<br v-if="locale !== 'zh-CN'" /> <span :class="{'clear-margin-left': locale !== 'zh-CN'}">{{$t("influenceProject3Title1")}}</span></p>
            <div class="slide-wrap"  :class="openAnimal ? 'slide-wrap-open' : ''">
              <p>{{$t("influenceProject3SubTitle1")}}</p>
              <p>{{$t("influenceProject3SubTitle2")}}</p>
              <span>{{$t("influenceProject3Explain")}}</span>
            </div>
            <div class="slide-cover">
              <img v-if="openAnimal" src="@/assets/images/guazhou-mobile/open.png" @click="openAnimalFun">
              <img v-else src="@/assets/images/guazhou-mobile/close.png" @click="openAnimalFun">
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <div class="card">
      <p class="measure-title">{{$t("residentTitle")}}</p>
      <p>{{$t("residentExplain1")}}</p>
      <p>{{$t("residentExplain2")}}{{$t("residentExplain21")}}</p>
      <p>{{$t("residentExplain3")}}{{$t("residentExplain31")}}{{$t("residentExplain32")}}</p>
      <img style="width: 100%;" src="@/assets/images/guazhou-mobile/resident.png" alt="">
      
    </div>
    <div class="card">
      <p class="measure-title">{{$t("downloadTitle")}}</p>
      <ul class="down">
        <li class="opcity">
          <p>{{$t("downloadProject1")}}</p>
          <div>
            <!-- @click="look('VCS4218_Guazhou_PD_ver3.0_项目描述文档')" -->
            <img src="@/assets/images/guazhou-mobile/see.png"  alt="">
            <!-- @click="download('VCS4218_Guazhou_PD_ver3.0_项目描述文档')" -->
            <img src="@/assets/images/guazhou-mobile/download.png"  alt="">
          </div>
        </li>
        <li class="opcity">
          <p>{{$t("downloadProject2")}}</p>
          <div>
            <!-- @click="look('ID_4218_Final%20Validation%20Report_Gansu_项目认证报告')"  -->
            <img src="@/assets/images/guazhou-mobile/see.png"  alt="">
            <!-- @click="download('ID_4218_Final%20Validation%20Report_Gansu_项目认证报告')" -->
            <img src="@/assets/images/guazhou-mobile/download.png"  alt="">
          </div>
        </li>
        <li class="opcity">
          <p>{{$t("downloadProject3")}}</p>
          <div>
            <img src="@/assets/images/guazhou-mobile/see.png" alt="">
            <img src="@/assets/images/guazhou-mobile/download.png" alt="">
          </div>
        </li>
        <li class="opcity">
          <p>{{$t("downloadProject4")}}</p>
          <div>
            <img src="@/assets/images/guazhou-mobile/see.png" alt="">
            <img src="@/assets/images/guazhou-mobile/download.png" alt="">
          </div>
        </li>
      </ul>
    </div>
    <!-- 内容区域   结束 -->
    <public-footer></public-footer>
  </div>
</template>
<script>
import carousel from '@/components/carousel.vue';
export default {
  components: {
    carousel
  },
  data() {
    return {
      locale: "",
      openBotany: false,
      openAnimal: false
    }
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
  methods: {
    open() {
      this.openBotany = !this.openBotany
    },
    openAnimalFun() {
      this.openAnimal = !this.openAnimal
    },
    download(str) {
      this.downloadFile(`./static/${str}.pdf`, str)
    },
    look(str) {
      this.openPdf(`./static/${str}.pdf`)
    },
    openPdf (url){
    // 创建隐藏的可下载链接
       window.open(url)
    },
    downloadFile (url,fileName) {   
      const x = new XMLHttpRequest();
      x.open('GET', url, true);
      x.responseType = 'blob';
      x.onload = function () {
        console.log(x.response)
        const url = window.URL.createObjectURL(x.response);
        const elink = document.createElement('a');
        elink.href = url;
        elink.target = '_self'; // 当前也 target打开新页面
        elink.setAttribute('download', fileName + '.pdf');
        elink.style.display = 'none';
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      };
      x.send();         
    },
  }
}

</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img ,.about-road img,.team-list li img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 80%;
  font-size: 0.36rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
 }
.guazhou-mobile .card {
  margin: 1rem 0.4rem;
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;
  padding: 0.4rem;
}
.guazhou-mobile .card p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;

  font-style: normal;
  margin-bottom: 0.4rem;
}
.card .represen{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card .represen div {
  width: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.4rem;
}
.card .represen div img {
  height: 1.56rem;
  margin-bottom: 0.4rem;
}
.card .represen div p, .card .represen div span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.5rem;
  text-align: center;
  font-style: normal;
} 
.card .represen div p {
  margin: 0;
}
.card  .represen-info, .card .title {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
}
.card .title {
  margin: 0.4rem 0;
}
.card .auth-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
.card .auth-img img:last-child{
  width: 100%;
  height: 0.8rem;
  width: 3.3rem;
  object-fit: contain;
}
.card .auth-img img:first-child {
  width: 2.3rem;
  margin-right: 0.4rem;
  height: 0.7rem;
  object-fit: contain;
}
.auth-img-one, .auth-img-two {
  margin-top: 0.6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.auth-img-one img, .auth-img-two img {
  margin-right: 0.6rem;
  width: 1.4rem;
  height: 1.4rem;
}
.auth-img-one img:last-child, .auth-img-two img:last-child {
  margin: 0;
}

/* 2 */
.guazhou-mobile .card .measure-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.48rem;
  color: #333333;
  line-height: 0.48rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
}
.guazhou-mobile .card ul li {
  padding-left: 0.48rem;
  position: relative;
  margin-bottom: 0.72rem;
}
.guazhou-mobile .card ul li:last-child {
  margin: 0;
}
.guazhou-mobile .card ul li img {
  width: 0.32rem;
  height: 0.56rem;
  position: absolute;
  top: -0.2rem;
  left: 0;
}
.guazhou-mobile .card ul li p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.4rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0
}
.guazhou-mobile .card ul li p:last-child {
  font-weight: 400;
}
.carousel-wrap {
  margin-top: -0.6rem;;
}
.carousel-wrap .swiper-slide{
  overflow: hidden;
  position: relative;
  margin-bottom: 0.6rem;
}
.carousel-wrap .swiper-slide img {
  height: 3.66rem;
  width: 100%;
}
.carousel-wrap .swiper-slide p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0 0.32rem;
  padding: 0 0.4rem
}
.carousel-wrap .swiper-slide p span {
  font-family: Italic;
  margin-left: 0.2rem;
}
.carousel-wrap .swiper-slide .slide-wrap {
  height: 2.4rem;
  overflow: hidden;
  padding: 0 0.4rem
}
.carousel-wrap .swiper-slide .slide-wrap span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
}
.carousel-wrap .swiper-slide .lenged {
  color: #333333;
  font-size: 0.24rem;
}
.carousel-wrap .swiper-slide .slide-cover {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1.28rem;
  background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0.9));
  cursor: pointer;
  text-align: center;
  width: 100%;
  line-height: 1.28rem;
}
.carousel-wrap .swiper-slide .slide-wrap-open {
  height: auto;
  overflow: auto;
  padding-bottom: 1.28rem;
}

.carousel-wrap .swiper-slide .slide-cover img {
  width: 0.6rem;
  height: 0.48rem;
}
.card h6, .carousel-wrap h6 {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.5rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0;
}
.video, .video .video-js {
  width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
  height: 4rem;
}
.video {
  margin-bottom: 0.4rem;
}
.influence-title {
  margin: 0.4rem 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  font-style: normal;
}
.card.clear-border {
  box-shadow: none;
}
.carousel-wrap h6 {
  padding: 0 0.4rem;
}
.carousel-wrap .swiper-wrapper .slide-wrap p{
  padding: 0;
  margin: 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
}

.card .down li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.6rem 0 0 ;
  padding: 0 !important;
  opacity: 0.6
}
.card .down li p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.5rem;
  text-align: left;
  font-style: normal;
  padding: 0;
}
.card .down li div img {
  margin-left: 0.4rem;
  position: relative;
  width: 0.4rem;
  height: 0.4rem;
  top: 0;
  cursor: pointer;
}
.card .down li:nth-child(3), .card .down li:nth-child(4) {
  opacity: 0.6
}
</style>
<template>
  <div class="homepage certificate-mobile" >
    <public-header></public-header>
    <div class="banner">
       <img src="@/assets/images/certificate/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("certificateTitleMobile")}} <br v-if="locale !== 'zh-CN'" />{{$t("certificateTitleMobile1")}}</p>
    </div>
    <!-- 内容区域   开始 -->
    <ul class="card">
      <li>
        <h6 class="title">{{$t("certificateContentTitle1")}}</h6>
        <p>{{$t("certificateContentExplain11")}}</p>
        <p>{{$t("certificateContentExplain12")}}</p>
        <p v-if="locale !== 'zh-CN'">{{$t("certificateContentExplain13")}}</p>
      </li>
      <li>
        <h6 class="title">{{$t("certificateContentTitle2")}}</h6>
        <p>{{$t("certificateContentExplain21")}}</p>
        <p>{{$t("certificateContentExplain22")}}</p>
      </li>
      <li>
        <h6 class="title">{{$t("certificateContentTitle3")}}</h6>
        <p>{{$t("certificateContentExplain3")}}</p>
        <div class="sub-content">
          <p class="title-font-weight">{{$t("certificateContentExplain3Sub1")}}</p>
          <p>{{$t("certificateContentExplain3Sub2")}}</p>
        </div>
        <img src="@/assets/images/certificate/map.png" :alt='$t("bannerTitle")'>
        <span>{{$t("certificateContentExplain3Origin")}}</span>
        <div class="sub-content">
          <p>{{$t("certificateContentExplain3Sub4")}}</p>
        </div>
        <div class="sub-content sub-content-30">
          <p class="title-font-weight">{{$t("certificateContentExplain3Sub5Title")}}</p>
          <p>{{$t("certificateContentExplain3Sub51")}}</p>
          <p>{{$t("certificateContentExplain3Sub52")}}</p>
        </div>
      </li>
      <li>
        <h6 class="title">{{$t("certificateContentTitle4")}}</h6>
        <img v-if="locale === 'zh-CN'" src="@/assets/images/certificate/model-zh.png" >
        <img v-else src="@/assets/images/certificate/model-en.png">
        <p>{{$t("certificateContentExplain4")}}</p>
        <div class="sub-content">
          <p>{{$t("certificateContentExplainSub1")}}</p>
          <p>{{$t("certificateContentExplainSub2")}}</p>
          <p>{{$t("certificateContentExplainSub3")}}</p>
          <p>{{$t("certificateContentExplainSub4")}}</p>
        </div>
        <img src="@/assets/images/certificate/info-zh.png">
        <span>{{$t("certificateContent4Origin")}}</span>
      </li>
    </ul>
    <div class="fold">
      <div>
        <p @click="openDetail(1)" :class="openOne? 'open': ''">{{$t("certificateContent4SubTitle1")}}</p>
        <ul v-if="openOne">
         <li>
          <span>{{$t("certificateContent4SubContent1")}}</span>
         </li>
         <li>
          <span>{{$t("certificateContent4SubContent2")}}</span>
          <span>{{$t("certificateContent4SubContent3")}}</span>
          <span>{{$t("certificateContent4SubContent4")}}</span>
         </li>
         <li>
          <span>{{$t("certificateContent4SubContent5")}}</span>
         </li>
         <li>
          <span>{{$t("certificateContent4SubContent6")}}</span>
         </li>
        </ul>
      </div>
      <div>
        <p @click="openDetail(2)" :class="openTwo? 'open': ''">{{$t("certificateContent4SubTitle2")}}</p>
        <ul v-if="openTwo">
         <li>
          <span>{{$t("certificateContent4SubContent7")}}</span>
          <span>{{$t("certificateContent4SubContentMobile8")}}</span>
         </li>
         <li>
          <span>{{$t("certificateContent4SubContentMobile81")}}</span>
         </li>
        </ul>
      </div>
      <div>
        <p @click="openDetail(3)" :class="openThree? 'open': ''">{{$t("certificateContent4SubTitle3")}}</p>
        <ul v-if="openThree">
         <li>
          <span>{{$t("certificateContent4SubContent9")}}</span>
         </li>
         <li>
          <span>{{$t("certificateContent4SubContent10")}}</span>
          <img id="imageZH" v-if="locale === 'zh-CN'" src="@/assets/images/certificate/table-zh.png" :alt='$t("bannerTitle")'>
          <img id="imageEN" v-else src="@/assets/images/certificate/table-en.png" :alt='$t("bannerTitle")'>
          <div class="fold-icon" @click="aboutIcon">
            <span class="fold-icon-inner">{{$t("seeImage")}}</span>
          </div>
         </li>
        </ul>
      </div>
    </div>
    <!-- 内容区域   结束 -->
    <info-submit />
    <public-footer></public-footer>
    <div v-show="false" class="images" v-viewer>
      <img v-if="locale === 'zh-CN'" src="@/assets/images/certificate/table-en.png" :alt='$t("bannerTitle")'>
      <img  v-else src="@/assets/images/certificate/table-zh.png" :alt='$t("bannerTitle")'>
    </div>
    <viewer v-show="false" :images="locale === 'zh-CN' ? imageszh : images ">
      <img v-if="locale === 'zh-CN'" src="@/assets/images/certificate/table-en.png" :alt='$t("bannerTitle")'>
      <img v-else src="@/assets/images/certificate/table-zh.png" :alt='$t("bannerTitle")'>
    </viewer>
  </div>
</template>
<script>
import infoSubmit from '@/components/info/index-mobile.vue';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)
VueViewer.setDefaults({
  'navbar': false,
    'toolbar': false,
    'transition': true, 
    'fullscreen': false, //播放时是否全屏
    'zoomOnTouch':true,
})
export default {
  components: {
    infoSubmit
  },
  data() {
    return {
      locale: "",
      openOne: false,
      openTwo: false,
      openThree: false,
      images: [
          require('@/assets/images/certificate/table-en.png')
        ],
      imageszh: [
          require('@/assets/images/certificate/table-zh.png')
        ],
    }
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
  methods: {
    openDetail(ev) {
      switch(ev) {
        case 1:
          this.openOne = !this.openOne
          break;
        case 2:
          this.openTwo = !this.openTwo
          break;
        case 3:
          this.openThree = !this.openThree
          break;
      }
    },
    aboutIcon() {
      let images = []
      if (this.locale === "zh-CN") {
        images.push(require('@/assets/images/certificate/table-zh.png'))
      } else {
        images.push(require('@/assets/images/certificate/table-en.png'))
      }
      this.$viewerApi({
        images: images
      })
    }
  }
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img ,.about-road img,.team-list li img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .certificate-mobile .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }

.asset-trad-title {
  padding: 1rem 0.4rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.5rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
}
.card {
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  margin: 1rem 0.4rem;
  padding:0.4rem 0.4rem 0.01rem 0.4rem;
}
.certificate-mobile .title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.48rem;
  color: #333333;
  line-height: 0.66rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin-bottom: 0.4rem;
}
.card li {
  margin-top: 0.6rem;
}
.card li:first-child {
  margin-top: 0;
}
.card p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin-bottom: 0.4rem;
}
.card .sub-content {
  padding-left: 0.3rem;
}
.card .sub-content p {
  margin: 0;
}
.card .sub-content .title-font-weight {
  font-weight: 600;
}
.card li img {
  width: 100%;
  margin: 0.36rem 0;
}
.card li span {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.2rem;
  color: #666666;
  line-height: 0.36rem;
  text-align: center;
  font-style: normal;
  margin-top: -0.36rem;
  margin-bottom: 0.36rem;
  width: 100%;
}
.card .sub-content-30 {
  margin-top: 0.6rem
}
.fold {
  padding: 0rem 0.4rem 0.6rem;
}
.fold div {
  width: 100%;
  margin-top: 0.6rem;
}
.fold div:first-child {
  margin: 0;
}
.fold div p {
  padding-left: 0.64rem;
  position: relative;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.48rem;
  color: #333333;
  line-height: 0.66rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
}
.fold div p::after {
  content: '';
  display: inline-block;
  width: 0.24rem;
  height: 0.44rem;
  position: absolute;
  top: 0.15rem;
  left: 0.15rem;
  background: url(../../assets/images/certificate/title-icon-none.png);
  background-size: 100% 100%;
}
.fold div p.open::after {
  content: '';
  display: inline-block;
  width: 0.44rem;
  height: 0.24rem;
  position: absolute;
  top: 0.25rem;
  left: 0;
  background: url(../../assets/images/certificate/title-icon.png);
  background-size: 100% 100%;
}
.fold div li {
  margin: 0.4rem 0 0.6rem;
}
.fold div li:last-child {
  margin: 0.4rem 0 0;
}
.fold div li span {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.6rem;
  text-align: left;
  font-style: normal;
}
.fold div li img {
  width: 100%;
  margin: 0.2rem 0;
}
.fold .fold-icon {
  width: 2.5rem;
  height: 0.8rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.22rem;
  color: #19A15F;
  line-height: 0.8rem;
  text-align: left;
  font-style: normal;
  /* background-image: url(../../assets/images/about-mobile/botton-border.png); */
  /* background-size: 100% 100%; */
  text-align: center;
  background: linear-gradient( to right, #11998E, #38EF7D);
  border-radius: 0.4rem;
  margin: 0 auto;
}
.fold div li .fold-icon .fold-icon-inner {
  display: block;
  height: 0.8rem;
  line-height: 0.8rem;
  margin: 0.02rem;
  color: #fff;
  border-radius: 0.38rem;
  letter-spacing: 4px;
  padding: 0 0.4rem;
  text-align: center;
}
</style>
<style>
.viewer-open {
  padding: 0 !important
}
</style>
<template>
  <div class="info-box">
    <div class="center info">
    <div class="info-title">
      <p>{{ $t("moreUs1") }}</p>
      <p>{{ $t("moreUs2") }}</p>
    </div>
    <div class="form">
      <el-form label-width="0" ref="ruleForm" :model="formLabelAlign">
        <div class="row">
          <el-form-item prop="nickName">
            <el-input
              v-model="formLabelAlign.nickName"
              :placeholder="$t('infoName')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="formLabelAlign.email"
              :placeholder="$t('infoEmail')"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item prop="companyName">
          <el-input
          class="clear-placeholder"
            v-model="formLabelAlign.companyName"
            :placeholder="$t('infoCompanyName')"
          ></el-input>
        </el-form-item>
        <el-form-item class="textarea" prop="leaveWord">
          <el-input
            type="textarea"
            v-model="formLabelAlign.leaveWord"
            :placeholder="$t('infoMeg')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="submit">{{
            $t("infoSubmit")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formLabelAlign: {
        nickName: "",
        email: "",
        companyName: "",
        leaveWord: "",
      },
      locale: "",
   
    };
  },
  created() {
    this.locale = localStorage.getItem("locale");
  },
  methods: {
    submit() {
      const { nickName, email, leaveWord } = this.formLabelAlign
      console.log(nickName, email, leaveWord)
      if (nickName && email && leaveWord) {
        this.httpRequest()
      }
    },
    httpRequest() {
      const _this = this
      let xhr;
      xhr = new XMLHttpRequest();
      xhr.open(
        "post",
        "https://www.qyh-investment.com/prod-api/system/visitor/add",
        true
      );
      xhr.setRequestHeader("content-type", "application/json");
      xhr.send(JSON.stringify(this.formLabelAlign));
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          let reg = /^2\d{2}/;
          if (reg.test(xhr.status)) {
            _this.$refs.ruleForm.resetFields()
          }
        }
      };
    },
  },
};
</script>
<style>
.info-box {
  padding: 0.4rem;
  margin-bottom: 0.6rem;
}
.info-box .info {
  padding: 0.48rem;
  width: 100%;
  height: 10.1rem;
  background-image: url(../../assets/images/about-mobile/inof.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0;
}
.info-box .info-title {
  padding: 0;
}
.info-box .info-title p {
  width: 100%;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.56rem;
  color: #ffffff;
  line-height: 0.6rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  padding: 0;
}
.info-box .form {
  width: 100%;
}
.info-box .form .el-form-item,
.info-box .form .el-form-item__content,
.info-box .form .el-input,
.info-box .form .el-input__inner,
.info-box .form .el-textarea,
.info-box .form .el-textarea__inner {
  height: 1.2rem;
  border-radius: 0.4rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.36rem;
  color: #333;
  line-height: 0.4rem;
  text-align: left;
  font-style: normal;
}
.info-box .form .textarea,
.info-box .form .el-textarea,
.info-box .form .el-textarea__inner {
  height: 3rem;
}
.info-box .form .el-input__inner,
.info-box .form .el-textarea__inner {
  padding: 0.48rem;
}
.info-box .form .el-input__inner::placeholder,
.info-box .form .el-textarea__inner::placeholder {
  color: #999999
}
.info-box .clear-placeholder .el-input__inner {
  color: #666!important
}
.info-box .el-form-item {
  margin-bottom: 0.24rem;
}
.info-box .form .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.info-box .form .row .el-form-item {
  margin-right: 0.4rem;
}
.info-box .form .row .el-form-item:last-child {
  margin: 0;
}
.info-box .form .row .el-form-item__content {
  width: 100%;
}
.info-box .form .el-form-item .el-button {
  height: 1.36rem;
  width: 100%;
  border-radius: 0.4rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.78rem;
  text-align: center;
  font-style: normal;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homepage carbon-mobile"},[_c('public-header'),_vm._v(" "),_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/images/carbon/banner-carbon.png"),"alt":_vm.$t("carbonTitle")}}),_vm._v(" "),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("carbonTitle")))])]),_vm._v(" "),_c('h6',{staticClass:"carbon-title"},[_vm._v(_vm._s(_vm.$t("carbonSubTitle1")))]),_vm._v(" "),_c('div',{staticClass:"carousel-warp"},[_c('carousel',{attrs:{"height":"auto","swiperName":"swiper9","paginationClassName":"pagination9"}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-title"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/carbon/tree-icon.png"),"alt":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonQualityTitle1")))])]),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonQualityContent1")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-title"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/carbon/tree-icon.png"),"alt":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonQualityTitle2")))])]),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonQualityContent2")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-title"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/carbon/tree-icon.png"),"alt":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonQualityTitle3")))])]),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonQualityContent3")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-title"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/carbon/tree-icon.png"),"alt":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonQualityTitle4")))])]),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonQualityContent4")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-title"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/carbon/tree-icon.png"),"alt":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonQualityTitle5")))])]),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonQualityContent5")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-title"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/carbon/tree-icon.png"),"alt":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonQualityTitle6")))])]),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonQualityContent6"))+_vm._s(_vm.$t("carbonQualityContent61")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun}})])])])])],1),_vm._v(" "),_c('h6',{staticClass:"carbon-title"},[_vm._v(_vm._s(_vm.$t("carbonSubTitle2")))]),_vm._v(" "),_c('div',{staticClass:"carousel-warp swiper10"},[_c('carousel',{attrs:{"height":"auto","swiperName":"swiper10","paginationClassName":"pagination10"}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide-write-bg"},[_c('div',{staticClass:"swiper-slide-title"},[_c('img',{staticClass:"swiper-slide-icon",attrs:{"src":require("@/assets/images/carbon/standard1.png"),"alt":""}}),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal1 && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal1 && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonStandardContent1")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal1)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun1}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun1}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-title"},[_c('img',{staticClass:"swiper-slide-icon",attrs:{"src":require("@/assets/images/carbon/standard2.png"),"alt":""}}),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal1 && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal1 && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonStandardContent2")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal1)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun1}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun1}})])]),_vm._v(" "),_c('div',{staticClass:"swiper-slide swiper-slide-write-bg"},[_c('div',{staticClass:"swiper-slide-title"},[_c('img',{staticClass:"swiper-slide-icon",attrs:{"src":require("@/assets/images/carbon/standard3.png"),"alt":""}}),_vm._v(" "),_c('p',{staticClass:"swiper-slide-content",class:{
              'swiper-slide-content-open': (_vm.openAnimal1 && _vm.locale === 'zh-CN'),
              'swiper-slide-content-open-en': (_vm.openAnimal1 && _vm.locale === 'en-US')
            }},[_vm._v(_vm._s(_vm.$t("carbonStandardContent3")))])]),_vm._v(" "),_c('div',{staticClass:"slide-cover"},[(_vm.openAnimal1)?_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/open.png")},on:{"click":_vm.openAnimalFun1}}):_c('img',{attrs:{"src":require("@/assets/images/guazhou-mobile/close.png")},on:{"click":_vm.openAnimalFun1}})])])])])],1),_vm._v(" "),_c('h6',{staticClass:"carbon-title"},[_vm._v(_vm._s(_vm.$t("carbonSubTitle3")))]),_vm._v(" "),_c('div',{staticClass:"card"},[_c('p',[_vm._v("\n      "+_vm._s(_vm.$t("carbonContent1"))),_c('router-link',{attrs:{"to":{path: '/project'}}},[_vm._v(_vm._s(_vm.$t("carbonContent2")))]),_vm._v(_vm._s(_vm.$t("carbonContent3"))+"\n    ")],1),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v(_vm._s(_vm.$t("carbonBoxContent")))]),_vm._v(" "),_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":require("@/assets/images/carbon/type1.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t("carbonProjectItemTitle1")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonProjectItemContent1")))])]),_vm._v(" "),_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":require("@/assets/images/carbon/type2.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t("carbonProjectItemTitle2")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonProjectItemContent2")))]),_vm._v(" "),_c('router-link',{attrs:{"to":{path: '/grassland-project'}}},[_c('p',[_vm._v(_vm._s(_vm.$t("moreTitle"))+" "),_c('i',{staticClass:"el-icon-arrow-right"})])])],1),_vm._v(" "),_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":require("@/assets/images/carbon/type3.png"),"alt":""}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t("carbonProjectItemTitle3")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("carbonProjectItemContent3")))])])]),_vm._v(" "),_c('info-submit'),_vm._v(" "),_c('public-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
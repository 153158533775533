<template>
  <div class="homepage">
    <public-header></public-header>
    <!-- banner -->
    <div class="banner">
       <img src="@/assets/images/about-mobile/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("aboutUs")}}</p>
    </div>
      <!-- 公司介绍 --> 
    <div class="about-mobile">
        <div class="about-button">
          <span class="about-icon" :class=" aboutActive ? 'about-icon-active' : ''" @click="aboutIcon(1)">
            <span class="about-icon-inner">{{$t("aboutButton1")}}</span>
          </span>
          <span class="about-icon" :class=" !aboutActive ? 'about-icon-active' : ''" @click="aboutIcon(2)">
            <span class="about-icon-inner">{{$t("aboutButton2")}}</span>
          </span>
        </div>
        <div v-if="aboutActive">
          <div class="about-mobile-card">
            <p>{{$t("whoWeAreContent1")}}</p>
            <p>{{$t("whoWeAreContent2")}}</p>
          </div>
          <carousel  :height="locale === 'zh-CN' ? '9.5rem' : '9.5rem'" swiperName="swiper6"  paginationClassName="pagination6">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="@/assets/images/about-mobile/staff1.png" :alt='$t("projectClassItemProjectTitle1")'>
                <p>{{$t("teamItem1")}}</p>
                <span>{{$t("teamItemPost1")}}</span>
                <router-link class="btn" :to="{path:'/CarlHao'}">
                  <p >{{$t("moreTitle")}}</p>
                </router-link>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/images/about-mobile/staff2.png" :alt='$t("projectClassItemProjectTitle1")'>
                <p>{{$t("teamItem2")}}</p>
                <span>{{$t("teamItemPost2")}}</span>
                <router-link class="btn" :to="{path:'/NeoYuyangLin'}">
                  <p >{{$t("moreTitle")}}</p>
                </router-link>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/images/about-mobile/staff3.png" :alt='$t("projectClassItemProjectTitle1")'>
                <p>{{$t("teamItem3")}}{{$t("teamItem31")}}</p>
                <span>{{$t("teamItemPost3")}}</span>
                <router-link class="btn" :to="{path:'/NicholasManthey'}">
                  <p >{{$t("moreTitle")}}</p>
                </router-link>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/images/about-mobile/staff4.png" :alt='$t("projectClassItemProjectTitle1")'>
                <p>{{$t("teamItem4")}}</p>
                <span>{{$t("teamItemPost4")}}</span>
                <router-link class="btn" :to="{path:'/BeibeiSong'}">
                  <p >{{$t("moreTitle")}}</p>
                </router-link>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/images/about-mobile/staff5.png" :alt='$t("projectClassItemProjectTitle1")'>
                <p>{{$t("teamItem5")}}</p>
                <span>{{$t("teamItemPost5")}}</span>
                <router-link class="btn" :to="{path:'/RyanChen'}">
                  <p >{{$t("moreTitle")}} </p>
                </router-link>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/images/about-mobile/staff6.png" :alt='$t("projectClassItemProjectTitle1")'>
                <p>{{$t("teamItem6")}}</p>
                <span>{{$t("teamItemPost6")}}</span>
                <router-link class="btn" :to="{path:'/aboutUs'}">
                  <p >{{$t("moreTitle")}}</p>
                </router-link>
              </div>
            </div>
          </carousel>
        </div>
        <div v-else class="course-relative">
          <div class="about-mobile-line" :class="locale === 'zh-CN' ? '': 'about-mobile-line-en'"></div>
          <!-- 2016 -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon"></i><span> 2016</span>
            </div>
            <div class="about-title">
              <p>
                {{$t("course2016Title")}}
              </p>
            </div>
            <i class="about-course-line"></i>
          </div>
          <!-- 2018 -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon"></i><span> 2018</span>
            </div>
            <div class="about-title">
              <p>
                {{$t("course2018Title1")}}
              </p>
              <p>
                {{$t("course2018Title2")}}
              </p>

            </div>
            <i class="about-course-line"></i>
          </div>
          <!-- 2019 -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon"></i><span> 2019</span>
            </div>
            <div class="about-title">
              <p>
                {{$t("course2019Title1")}}
              </p>
              <img src="@/assets/images/about-mobile/cop25.png" alt="">
              <p>
                {{$t("course2019Title2")}}
              </p>
              <p>
                {{$t("course2019Title3")}}
              </p>
              <img src="@/assets/images/about-mobile/inn.png" alt="">
            </div>
            <i class="about-course-line"></i>
          </div>
          <!-- 2020 -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon icon1"></i><span> 2020</span>
            </div>
            <div class="about-title">
              <p>
                {{$t("course2020Title1")}}
              </p>
              <p>
                {{$t("course2020Title2")}}
              </p>
              <img src="@/assets/images/about-mobile/ieta.png" alt="">
            </div>
            <i class="about-course-line"></i>
          </div>
          <!-- 2021 -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon icon1"></i><span> 2021</span>
            </div>
            <div class="about-title">
              <p>{{$t("course2021Title1")}}</p>
              <img src="@/assets/images/about-mobile/vick.png" alt="">
              <p>{{$t("course2021Title2")}}</p>
              <p>{{$t("course2021Title3")}}</p>
              <img src="@/assets/images/about-mobile/cop26.png" alt="">
            </div>
            <i class="about-course-line"></i>
          </div>
          <!-- 2022 -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon icon1"></i><span> 2022</span>
            </div>
            <div class="about-title">
              <p>{{$t("course2022Title1")}}</p>
              <p>{{$t("course2022Title2")}}</p>
                <img src="@/assets/images/about-mobile/encr.png" alt="">
              <p>{{$t("course2022Title3")}}</p>
              <img src="@/assets/images/about-mobile/rec.png" alt="">
              <p>{{$t("course2022Title4")}}</p>
              <p>{{$t("course2022Title5")}}</p>
              <img src="@/assets/images/about-mobile/9am.png" alt="">
              <p v-if="locale === 'zh-CN'">{{$t("course2022Title6")}}</p>
            </div>
            <i class="about-course-line"></i>
          </div>
          <!-- 2023 -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon icon1"></i><span> 2023</span>
            </div>
            <div class="about-title">
              <p>{{$t("course2023Title1")}}</p>
              <p>{{$t("course2023Title2")}}</p>
              <img src="@/assets/images/about-mobile/carbon.png" alt="">
              <p>{{$t("course2023Title3")}}</p>
              <img src="@/assets/images/about-mobile/zg.png" alt="">
              <p>{{$t("course2023Title4")}}</p>
              <img src="@/assets/images/about-mobile/dx.png" alt="">
            </div>
            <i class="about-course-line"></i>
          </div>
          <!-- 2024+ -->
          <div class="about-course">
            <div class="about-year">
              <i class="icon icon1"></i><span> 2024+</span>
            </div>
            <div class="about-title"><p>{{$t("course2024Title1")}}</p></div>
            <i class="about-course-line"></i>
          </div>
        </div>
    </div>
    <!-- 公司人员信息 -->
    <info-submit />
    <public-footer></public-footer>
  </div>
</template>
<script>
import carousel from '@/components/carousel.vue';
import infoSubmit from '@/components/info/index-mobile.vue';
export default {
  name: 'index',
  components:{
    carousel,
    infoSubmit
  },
  data() {
    return {
      locale: '',
      aboutActive: true
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
   methods: {
    aboutIcon() {
      this.aboutActive = !this.aboutActive
    }
  }
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img ,.about-road img,.team-list li img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }
.banner .btn {
  width: 1.6rem;
  height: 0.52rem;
  position: absolute;
  bottom: 0.76rem;
  left: 50%;
  margin-left: -0.8rem;
}



.about-mobile {
  padding: 0.4rem 0;
}
.about-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0.4rem;
}
.about-icon {
  min-width: 1.8rem;
  height: 0.8rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.22rem;
  color: #19A15F;
  line-height: 0.8rem;
  text-align: left;
  font-style: normal;
  /* background-image: url(../../assets/images/about-mobile/botton-border.png); */
  /* background-size: 100% 100%; */
  text-align: center;
  background: linear-gradient( to right, #11998E, #38EF7D);
  border-radius: 0.4rem;
}
.about-icon .about-icon-inner {
  display: block;
  min-width: 1.76rem;
  height: 0.76rem;
  background: #fff;
  margin: 0.02rem;
  border-radius: 0.38rem;
  padding: 0 0.4rem;

}
.about-icon.about-icon-active .about-icon-inner {
  background: linear-gradient( to right, #11998E, #38EF7D);

  color: #fff;
}
.about-mobile-card {
  border-radius: 0.6rem;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.50);
  padding: 0.4rem;
  margin: 0 0.4rem 1rem;

}
.about-mobile-card p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 0.4rem;
}
.about-mobile-card p:last-child {
  margin-bottom: 0;
}

::v-deep .swiper-slide {
  padding: 1.1rem 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}


::v-deep .swiper-wrapper img {
  width: 3.4rem;
  height: 3.4rem;
}

::v-deep .swiper-wrapper p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.48rem;
  color: #333333;
  line-height: 0.48rem;
  text-align: left;
  font-style: normal;
  margin: 0.6rem auto 0.26rem;
}
::v-deep .swiper-wrapper span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #999999;
  line-height: 0.32rem;
  text-align: left;
  font-style: normal;
  margin-bottom: 0.6rem;
}
::v-deep .swiper-wrapper .btn {
  display: block;
  width: 2.1rem;
  height: 0.8rem;
  font-family: PingFangSC, PingFang SC;
  font-style: normal;
  background-image: url(../../assets/images/about-mobile/botton-border.png);
  background-size: 100% 100%;
}
::v-deep .swiper-wrapper a p {
  margin: 0;
  font-size: 0.24rem;
  font-weight: 400;
  text-align: center;
  line-height: 0.8rem;
  color: #19A15F;
}

.about-course {
  position: relative;
  margin-bottom: 1rem;
}
.about-year {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.66rem;
  padding: 0.16rem 0.4rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #333333;
  text-align: left;
  font-style: normal;
  display: inline-block;
  height: 1.08rem;
}
.about-year .icon {
  display: inline-block;
  width: 0.72rem;
  height: 0.72rem;
  background-image: url(../../assets/images/about-mobile/tree.png);
  margin-right: 0.4rem;
  background-size: 100%;
}
.about-year .icon.icon1 {
  background-image: url(../../assets/images/about-mobile/tree1.png);
}
.about-year span {
  line-height: 0.72rem;
  font-size: 0.36rem;
  height: 0.72rem;
  display: inline-block;
  position: relative;
  top: -0.2rem;
}
.about-title {
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  padding: 0.4rem;
  border-radius: 0.66rem;
  margin-top: 0.4rem;
  margin-left: 1.6rem;
  width: calc(100% - 2rem);
  position: relative;
  z-index: 1;
  background: #fff;
 
}
.about-title p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.4rem;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0 0;
}
.about-title p:first-child {
  margin: 0;
}
.about-title img {
  margin-top: 0.4rem;
  height: 0.8rem;
  max-width: 100%;
}
.about-course-line {
  position: absolute;
  top: 2rem;
  left: 0.72rem;
  width: 1.48rem;
  height: 2px;
  background: url(../../assets/images/about-mobile/line.png);
  background-size: cover;
}
.course-relative {
  position: relative;
  padding-left: 0.4rem;
}
.about-mobile-line {
  position: absolute;
  left: 1.12rem;
  top: 1rem;
  width: 2px;
  height: calc(100% - 2.4rem);
  background: url(../../assets/images/about-mobile/line-s.png);
  background-size: auto;
  background-repeat: repeat;
  
}
.about-mobile-line.about-mobile-line-en {
  height: calc(100% - 3.4rem);
}
</style>
